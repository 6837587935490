<template>
  <div>
    <b-card title="Add Post" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <p class="h3">Main Data</p>
            </b-col>
            <!-- cover -->
            <b-col md="12">
              <div align="center" class="text-center">
                <h4>Cover</h4>
                <b-media class="mb-1 mr-auto">
                  <b-avatar class="" ref="previewEl" :src="img" size="180px" />
                </b-media>
                <div class="mb-4 ">
                  <b-button variant="primary" @click="$refs.refInputEl.click()">
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @change="uploadImage"
                    />
                    <feather-icon icon="EditIcon" class=" " />
                  </b-button>
                </div>
              </div>
            </b-col>
            <!-- active -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- category -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-group
                  label="Category"
                  label-for="Category"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Category"
                    v-model="categorySelect"
                    :state="categorySelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="categoryOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- commentable -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Commentable"
                rules="required"
              >
                <b-form-group
                  label="Commentable"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Commentable"
                    v-model="commentableSelect"
                    :state="commentableSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="commentableOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Brand -->
            <b-col md="6">
              <b-form-group label="Brand" label-for="Brand">
                <v-select
                  id="brand"
                  v-model="brandSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="brandOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
              </b-form-group>
            </b-col>
            <!-- item -->
            <b-col md="6">
              <b-form-group label="Item" label-for="Item">
                <v-select
                  id="item"
                  v-model="itemSelect"
                  :disabled="itemSe"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="itemOptions.map(item => ({ value : item.id , text:item.name }))"
               
                  label="text"
                />
              </b-form-group>
            </b-col>
            <!-- Tags -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="tag"
                rules="required"
              >
                <b-form-group
                  label="Tag"
                  label-for="tag"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    multiple
                    id="tag"
                    mu
                    v-model="tagSelect"
                    :state="tagSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tagOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12">
              <p class="h3">Post Body</p>
            </b-col>
            <!-- title -->
            <b-col md="6">
              <b-form-group label="Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <!-- Description -->
              <b-col md="12">
                <b-form-group label="Body" label-for="Body">
                  <validation-provider
                    #default="{ errors }"
                    name="Body"
                    rules="required"
                  >
                    <vue-editor
                      id="Body"
                      v-model="data.body"
                      :state="errors.length > 0 ? false : null"
                      rows="20"
                    ></vue-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
       <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>

import { VueEditor } from "vue2-editor";
import { heightTransition } from "@core/mixins/ui/transition";
import { required } from "@validations";
export default {
  components: {
    VueEditor,
  },
 
  mixins: [heightTransition],
  watch: {
    brandSelect: function() {
      this.itemOptions = [
        {
          value: "select_value",
          text: "Select Value",
        },
      ];
      if (this.brandSelect != null) {
        this.getItems();
      } else {
        this.itemSe = true;
      }
    },
  },
  data() {
    return {
      id: 0,
      ProfilePictureBase64: [],
      img: "",
      itemSe: true,
      activeSelect: {
        text: "",
        value: "",
      },
      activeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "0",
          text: "False",
        },
        {
          value: "1",
          text: "True",
        },
      ],
      commentableSelect: {
        text: "",
        value: "",
      },
      commentableOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "0",
          text: "False",
        },
        {
          value: "1",
          text: "True",
        },
      ],
      categorySelect: {
        text: "",
        value: "",
      },
      categoryOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      itemSelect: {
        text: "",
        value: "",
      },
      itemOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      brandSelect: {
        text: "",
        value: "",
      },
      brandOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      tagSelect: [],
      tagOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      data: {
        active: "",
        title: "",
        body: "",
        commentable: "",
        item_id: "",
        post_category_id: "",
        tags: [],
      },
      postData: {},
      errors_back: "",
      showDismissibleAlert: false,
      required,
    };
  },
  methods: {
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.active = this.activeSelect.value;
          this.data.commentable = this.commentableSelect.value;
          this.data.post_category_id = this.categorySelect.value;
          if (this.itemSelect != null) {
            this.data.item_id = this.itemSelect.value;
          } else {
            delete this.data.item_id;
          }
          this.data.tags = [];
          for (let tag in this.tagSelect) {
            this.data.tags.push(this.tagSelect[tag].value);
          }
          if (this.img.includes("data:image/")) {
            this.data.cover = this.img.split(",").map((item) => item.trim());
            this.data.cover = this.data.cover[1];
          }
          for (let trn in this.data.translations) {
            this.data.translations[trn].locale = this.tranLocaleSelect[
              trn
            ].value;
          }
          axios
            .put("posts/" + this.id, this.data)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    getItems() {
      axios
        .get("products/brands/" + this.brandSelect.value + "/items/get")
        .then((result) => {
          const data = result.data.data;
          this.itemSe = false;
         this.itemOptions = data
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    // cencode cover
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        this.img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
    // basic
  },
  created() {
    this.id = this.$route.params.id;
    axios
      .get("posts/" + this.id)
      .then((result) => {
        this.postData = result.data.data;
        this.img = this.postData.cover;
        this.data.title = this.postData.title;
        this.data.body = this.postData.body;
        if (this.postData.item != null) {
          this.itemSelect.value = this.postData.item.id.toString();
          this.itemSelect.text = this.postData.item.item_translations.name;
          this.brandSelect.text = this.postData.item.brand.translation.name;
          this.brandSelect.value = this.postData.item.brand.id.toString();
          this.getItems();
          this.itemSe = false;
        }
        if (this.postData.active == true) {
          this.activeSelect.value = "1";
          this.activeSelect.text = "True";
        } else {
          this.activeSelect.value = "0";
          this.activeSelect.text = "False";
        }
        if (this.postData.commentable == true) {
          this.commentableSelect.value = "1";
          this.commentableSelect.text = "True";
        } else {
          this.commentableSelect.value = "0";
          this.commentableSelect.text = "False";
        }
        this.categorySelect.value = this.postData.post_category.id.toString();
        this.categorySelect.text = this.postData.post_category.post_category_translations.name;
        for (let index in this.postData.tags) {
          this.tagSelect.push({
            text: this.postData.tags[index].name,
            value: this.postData.tags[index].id.toString(),
          });
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
    axios
      .get("post-categories")
      .then((result) => {
        const data = result.data.data;
        if (data.length > 0) {
          for (let ind in data) {
            if (data[ind].post_category_translations != null) {
              this.categoryOptions.push({
                text: data[ind].post_category_translations.name,
                value: data[ind].id.toString(),
              });
            } else {
              this.categoryOptions.push({
                text: "undefinded",
                value: data[ind].id.toString(),
              });
            }
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
    axios
      .get("products/create")
      .then((result) => {
        const data = result.data.data;
        if (data.brands.length > 0) {
          for (let ind in data.brands) {
            if (data.brands[ind].translation != null) {
              this.brandOptions.push({
                text: data.brands[ind].translation.name,
                value: data.brands[ind].id.toString(),
              });
            }
          }
        }
        if (data.tags.length > 0) {
          for (let ta in data.tags) {
            this.tagOptions.push({
              value: data.tags[ta].id.toString(),
              text: data.tags[ta].name,
            });
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped></style>
